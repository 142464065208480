.ant-layout-sider-children
  display flex
img[src=""], img:not([src])
  opacity 0
// img 
//   position relative
// img::after 
//   content ""
//   display block
//   position absolute
//   z-index 2
//   top 0
//   left 0
//   width 100%
//   height 100%
//   font-size 12px
//   background-color #333

.logo
  width 20px
  height 20px
  margin-right 5px
  margin-top -2px